import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PermissionType } from '@shared/models/permission-type';

@Injectable({
  providedIn: 'root'
})
export class UserperrmissionService {

  constructor(private http: HttpClient) { }


  private permissionList = {};

  setPermissionList(permissionList) {
    this.permissionList = permissionList;
  }
  getPermissionList() {
    return this.permissionList;
  }
  isGranted(moduleName,subModuleName,permission) {
    var  intersection = false
    // const permissions = PermissionsFactory.getInstance().permissions;
     if(this.permissionList){
      if (this.permissionList.hasOwnProperty(moduleName)) { 
        if (this.permissionList[moduleName].hasOwnProperty(subModuleName)) { 
          permission.forEach(element =>{
            if(this.permissionList[moduleName][subModuleName][0].includes(element)){
              intersection= true;
            }
          } );
        if(intersection){
          return true;
        }
        else{
          return false;
        }
        }else{
          return false;
        }
      }
      else{
        return false;
      }
     }

    return false;
  }

  getPermisedModules(): Observable<any[]> {
    return this.http.get<any>(environment.commonmanagementapi + 'customuserapi');
  }

  getUserPermissionList(): Observable<any[]> {
    return this.http.get<any>(environment.commonmanagementapi + 'customuserapi/userPermissionList');
  }

  searchUserAPis(payload: any): Observable<any> {
    return this.http.post<any>(environment.commonmanagementapi + 'userapi/search', payload);
  }

  searchUserPermission(payload: any): Observable<any> {
    return this.http.post<any>(environment.commonmanagementapi + 'userpermission/search', payload);
  }

  getUserAPIUrl(modulename: string): Observable<any[]> {
    return this.http.get<any>(environment.commonmanagementapi + 'customuserapi/getUserAPIUrl/' + modulename);
  }

  updateUserAPIs(payload: any): Observable<any> {
    return this.http.put<any>(environment.commonmanagementapi + 'customuserapi', payload);
  }

  savePermission(payload: any): Observable<any> {
    return this.http.post<any>(environment.commonmanagementapi + 'userpermission', payload);
  }

  deleteAllPermission(payload: any): Observable<any> {
    return this.http.post<any>(environment.commonmanagementapi + 'customuserapi/deleteAll', payload);
  }

  copyPermission(newUserid: string, userId: string): Observable<any[]> {
    return this.http.get<any>(environment.commonmanagementapi + 'userpermission/copyRole/' + newUserid + "/" + userId);
  }

  deleteRole(userId: string): Observable<any[]> {
    return this.http.delete<any>(environment.commonmanagementapi + 'userpermission/deleteRole/' + userId);
  }
}
