import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@services/authentication.service';
import { SpinnerService } from '@services/core/spinner.service';
import { HandleErrorServiceService } from '@services/core/handle-error-service.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private location: Location,
    private router: Router,
    private spinnerService: SpinnerService,
    private errorSercice: HandleErrorServiceService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        this.spinnerService.spinnerEnded();
        // if (
        //   err.status == 400 ||
        //   err.status == 401 ||
        //   err.status == 403 ||
        //   err.status == 404
        //   // ||       err.status == 500
        // ) {
        // this.location.replaceState('/'); // clear browser navigation history and reset it to root. /
        //const error = err.error.message || err.statusText;
        if (err.status == 403 || err.status == 401 || err.status == 408) {
          this.location.replaceState('/'); // clear browser navigation history and reset it to root. /
          this.authenticationService.logout();
          this.router.navigate(['login'], {
            queryParams: {
              status: err.status,
              message: 'Invalid Username or password',
            },
          });
          this.errorSercice.handleError(err);
          return throwError(err);
        } else {
          this.errorSercice.handleError(err);
          return throwError(err);
        }
        //   this.router.navigate(['error'], {
        //     queryParams: { status: err.status, message: 'Unexpected Error' },
        //   });       
      })
    );
  }
}
