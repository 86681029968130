import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../app/_helpers/auth.guard';
import { PermissionGuard } from '../app/_helpers/permission.guard';

const routes: Routes = [
 //  { path: '', redirectTo: 'APMG', pathMatch: 'full' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
 
  {
    path: 'login',

    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)

  },

  {
    path: 'acceptquote',

    loadChildren: () => import('./acceptquote/acceptquote.module').then(m => m.AcceptquoteModule)

  },
  {
    path: 'mfalogin',
    loadChildren: () => import('./login-with-mfa/login-with-mfa.module').then(m => m.LoginWithMFAModule)
  },
  {
    path: 'home',
    data: { breadcrumb: 'Home' },
    canActivate: [AuthGuard,PermissionGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)

  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
