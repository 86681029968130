import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { UserperrmissionService } from '@services/commommgmnt/userpermission/userperrmission.service';
import { SpinnerService } from '@services/core/spinner.service';
import { Observable } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';

@Injectable({
  providedIn: 'root'
})

export class PermissionGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionManagerS: UserperrmissionService,private spinnerService: SpinnerService,
) { }
permissionList={};
   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  
   
if(this.permissionManagerS.getPermissionList()){
     const res= this.getpermissionList();
      return res;
      }

}
  async getpermissionList(){
   await this.getAsynclist();
  return true; 
 }
async getAsynclist(){
  let res =  await this.permissionManagerS.getUserPermissionList().toPromise();
  this.permissionManagerS.setPermissionList(res);
  
  
  // return new Promise((resolve,reject)=>{
  //   this.permissionManagerS.getUserPermissionList().subscribe( data=>{
  // if(data){
  //   resolve(data)
  //     this.permissionManagerS.setPermissionList(data);
  // }else{

  // }
    //  });
  // })
}
}
