import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor() { }

  private count = 0;
  private spinner$ = new BehaviorSubject<string>('');


  /**
 * To get spinner observables
 */
  getSpinnerObservable() {
    return this.spinner$;
    
  }

  /**
   * To start spinner 
   */
  spinnerStarted() {
    if (++this.count === 1) {
      this.spinner$.next('start');
    }

  }

  /**
   * To stop spinner 
   */
  spinnerEnded() {
    if (this.count === 0 || --this.count === 0) {
      this.spinner$.next('end');
    }
  }

  /**
   * To reset spinner 
   */
  spinnerReset() {
    this.count = 0
    this.spinner$.next('end');
  }
}

