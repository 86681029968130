import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { QuoteService } from '@services/receivables/quote/quote.service';
import { SalesorderService } from '@services/receivables/salesorder.service';

@Component({
  selector: 'app-acceptquote',
  templateUrl: './acceptquote.component.html',
  styleUrls: ['./acceptquote.component.scss']
})
export class AcceptquoteComponent implements OnInit {
  quoteId:any;
  message :any="Are you sure to accept this Quote from Apollo Manufacturing?"
  hide:any=true;

  constructor(
    private salesOrderService:SalesorderService,
    private quoteSerivce:QuoteService,
    private activatedRoute: ActivatedRoute) 
    {

     }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.quoteId = params['quoteId'];
      });
  }

 

  acceptQuote()
   {
 this.quoteSerivce.getQuoteMaster(this.quoteId).subscribe(dataquote => {
  if(dataquote&&dataquote.status!='A'){
    this.salesOrderService.acceptQuote
    ({"quoteId":Number(this.quoteId)}).subscribe(data => {
    if(data&&data.orderNo){
      this.message="Thank you for accepting this Quote. Your Order # : "+data.orderNo+"  has been created. Please logon to apmg.us to review freight and TERMS of SALE .Please contact us if further clarification is required.";
      this.hide=false;
    }
  
    });
  }
  else{
    this.message="This Quote is already accepted.....";
  }
  });
  }

}
