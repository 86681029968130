export const environment = {
  production: true,
  api: 'https://emp.apmg.us',
  usrmanagementapi: 'https://emp.apmg.us/usermanagement/v1/',
  inventorymanagementapi: 'https://emp.apmg.us/inventorymanagement/v1/',
  receivablemanagementapi: 'https://emp.apmg.us/receivablemanagement/v1/',
  manufacturemanagementapi: 'https://emp.apmg.us/manufacturemanagement/v1/',
  payablesmanagementapi: 'https://emp.apmg.us/payablesmanagement/v1/',
  commonmanagementapi: 'https://emp.apmg.us/commonmanagement/v1/',
  marketingmanagementapi: 'https://emp.apmg.us/marketingmanagement/v1/',
  reportmanagementapi: 'https://emp.apmg.us/commonmanagement/v1/',
  customermanagementapi:'https://apmg.us/',
  callcentremanagementapi: 'https://emp.apmg.us/callcentremanagement/v1/',
  offsetSeconds: 900
};
