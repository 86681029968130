<body>

  <div class="login-bg">
    <div class="login-bg-left accept-login">
      <div class="login-bg-left-container">
        <div class="mobile-login-logo">
          <img src="../../assets/imges/mobile-login-logo.png" />
        </div>
        <div class="login-bg-left-inner">
          <div class="login-bg-left-logo">
            <img src="../../assets/imges/logo-login.png" />
          </div>
          <div class="login-bg-left-head">Accept quote</div>
       {{message}}
        </div  >
        <div class="accept-quote-bt-outer">
        <div  [hidden]="!hide"  class="common-button"><a (click)="acceptQuote()" class="button-white" >Apply</a></div>
      </div>
      </div>

    </div>

  </div>
</body>  
  
  
  <div>
  
     
  
</div>


