import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HandleErrorServiceService {
  constructor(private toasters: ToastrService) { }

  // Handling HTTP Errors using Toaster
  public handleError(err: HttpErrorResponse) {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
 
     
      switch (err.status) {
    
        case 400:
          errorMessage = "Bad Request.";

          if(err.error.error_description == 'Invalid code'){ // when user enters wrong token
            errorMessage = "Invalid Token.";
          }          
          else if(err.error.error_description == 'Bad credentials'){ //when user enters wrong password
            errorMessage = "Invalid credential.";
          }
          break;

        case 401:
          errorMessage = "You need to log in to do this action.";

          if(err.error.error_description == 'No value present'){ // when user enters wrong username, error msg value adjusted
            errorMessage = "Invalid credential.";
          }        
          break;
        case 403:
          errorMessage = "You don't have permission to access the requested resource.";
          break;
        case 404:
          errorMessage = "The requested resource does not exist.";
          break;
        case 412:
          errorMessage = "Precondition Failed.";
          break;
        case 500:
          errorMessage = "Internal Server Error.";
          break;
        case 503:
          errorMessage = "The requested service is not available.";
          break;
        case 422:
          errorMessage = "Validation Error!";
          break;
        case 408:
          errorMessage = "Token Expired!";
          break;
        default:
          errorMessage = "Something went wrong!";
      }
    }
    if (errorMessage) {
      this.toasters.error(errorMessage);
    }
  }
}
