import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { goldpass } from '@models/goldpassword';

@Injectable({
  providedIn: 'root'
})
export class CustomService {


  public goldpriceaccessSubject: BehaviorSubject<goldpass>;
    public goldprice: Observable<goldpass>;

  constructor(private http: HttpClient,) { 
    this.goldpriceaccessSubject = new BehaviorSubject<goldpass>(JSON.parse(localStorage.getItem('goldPass')));
        this.goldprice = this.goldpriceaccessSubject.asObservable();
  }

  public get goldpassValue(): goldpass {
    return this.goldpriceaccessSubject.value;
}
  findmarketpriceofaninventoryitem(itemCode: any): Observable<any> {
    let qryParam = "?itemCode=" + itemCode;
    return this.http.get<any>(environment.manufacturemanagementapi + 'custommanufacture/findmarketpriceofaninventoryitem' + qryParam);
  }

  getPriceDetails(itemCode: any): Observable<any> {
    let qryParam = "?itemCode=" + itemCode;
    return this.http.get<any>(environment.manufacturemanagementapi + 'custommanufacture/pricedetails' + qryParam);
  }
  adcavceSearch(payLoad: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?start=" + page + "&size=" + size;
    return this.http.post<any>(environment.manufacturemanagementapi + 'custommanufacture/customsearch' + qryParam, payLoad);
  }
  deletejobfrommanfbyJobno(jobno: any) {
    return this.http.delete<any>(environment.manufacturemanagementapi + 'manufacture/' + jobno);
  }
  catalogCustomSearch(payLoad: any, page: number, size: number): Observable<any> {
    let qryParam = "?start=" + page + "&size=" + size;
    return this.http.post<any>(environment.manufacturemanagementapi + 'custommanufacture/catalogcustsearch' + qryParam, payLoad);
  }
  stoneUsedReportAdvsearch(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?start=" + page + "&size=" + size;
    return this.http.post<any>(environment.manufacturemanagementapi + 'custommanufacture/stoneusedreport' + qryParam, payload);
  }
  runJobStatementReportAdvsearch(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?start=" + page + "&size=" + size;
    return this.http.post<any>(environment.manufacturemanagementapi + 'custommanufacture/runjobstatementreport/' + qryParam, payload);
  }

  manufreportdetails(payLoad: any): Observable<any> {
    return this.http.post<any>(environment.manufacturemanagementapi + 'custommanufacture/manufreportdetails', payLoad);
  }
  assignEmployeeTrackReportAdvsearch(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?start=" + page + "&size=" + size;
    return this.http.post<any>(environment.manufacturemanagementapi + 'custommanufacture/assignemployeetrackreport' + qryParam, payload);
  }
  assignVendorTrackReportAdvsearch(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?start=" + page + "&size=" + size;
    return this.http.post<any>(environment.manufacturemanagementapi + 'custommanufacture/assignvendortrackreport/' + qryParam, payload);
  }
  getManufactureStonedetails(jobNo: any, pieces: any): Observable<any> {
    let qryParam = "?jobNo=" + jobNo + "&pieces=" + pieces;
    return this.http.get<any>(environment.manufacturemanagementapi + 'custommanufacture/getManufactureStonedetails' + qryParam);
  }
  getManufactureFindings(jobNo: any, pieces: any): Observable<any> {
    let qryParam = "?jobNo=" + jobNo + "&pieces=" + pieces;
    return this.http.get<any>(environment.manufacturemanagementapi + 'custommanufacture/getManufactureFindings' + qryParam);
  }
  getManufactureMaterial(jobNo: any, pieces: any): Observable<any> {
    let qryParam = "?jobNo=" + jobNo + "&pieces=" + pieces;
    return this.http.get<any>(environment.manufacturemanagementapi + 'custommanufacture/getManufactureMaterial' + qryParam);
  }
  utilityPassChecking(value: any): Observable<any> {
    let qryParam = "?value=" + value;
    return this.http.get<any>(environment.manufacturemanagementapi + 'custommanufacture/utilitypasschecking' + qryParam)
    .pipe(map(goldpass => {
      
      // store user details and jwt token in local storage to keep user logged in between page refreshe
      localStorage.setItem('goldPass', JSON.stringify(goldpass));
      this.goldpriceaccessSubject.next(goldpass);
      return goldpass;
  }));
  }

}
