import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class JwtDecodeService {

  constructor() { }

  /**
   * Decode token
   * @param token 
   * @returns decoded token info
   */
  decodeJWTToken(token: string) {
    return jwt_decode(token);
  }

  /**
   * 
   * @param token 
   * @returns emp_type
   */

  getEmpType(token: string) {
    return this.getAuthority(token)[1];
  }

  /**
   * 
   * @param token 
   * @returns emp_id
   */
  getEmpId(token: string) {
    return this.getAuthority(token)[0];
  }

  /**
   * 
   * @param token 
   * @returns userid
   */
  getUserId(token: string) {
    let tokenDecoded = this.decodeJWTToken(token);
    return tokenDecoded["sub"];
  }

  /**
   * Get the authority info
   * @param token 
   * @returns 
   */
  getAuthority(token: string) {
    let tokenDecoded = this.decodeJWTToken(token);
    if (tokenDecoded["authorities"]?.length > 0) {
      return tokenDecoded["authorities"][0].split('::');
    }
  }

  isExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

}
