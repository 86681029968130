import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  constructor(private http: HttpClient) {

  }

  saveQuoteMaster(quotePayLoad):Observable<any> {

    return this.http.post<any>(environment.receivablemanagementapi + 'QuoteData', quotePayLoad);
  }

  updateQuoteMaster(quoteNo:number,quotePayLoad):Observable<any> {

    return this.http.put<any>(environment.receivablemanagementapi + 'QuoteData/'+quoteNo, quotePayLoad);
  }


  getQuoteMaster(quoteNo:number): Observable<any> {
    
    return this.http.get<any>(environment.receivablemanagementapi + 'QuoteData/'+quoteNo );
  }

  
  saveQuoteItems(quotePayLoad):Observable<any> {

    return this.http.post<any>(environment.receivablemanagementapi + 'quoteitems', quotePayLoad);
  }

  updateQuoteItems(id:number,quotePayLoad):Observable<any> {

    return this.http.put<any>(environment.receivablemanagementapi + 'quoteitems/'+id, quotePayLoad);
  }

  getQuoteItemsByQuoteNo(quotePayLoad):Observable<any> {

    return this.http.post<any>(environment.receivablemanagementapi + 'quoteitems/search', quotePayLoad);
  }

  deleteQuoteItem(id: any): Observable<any> {
    return this.http.delete<any>(environment.receivablemanagementapi + 'quoteitems/' + id);
  }
  
}
