<body>

  <div class="login-bg">
    <div class="login-bg-left">
      <div class="login-bg-left-container">
        <div class="mobile-login-logo">
          <img src="../../assets/imges/mobile-login-logo.png" />
        </div>
        <div class="login-bg-left-inner">
          <div class="login-bg-left-logo">
            <img src="../../assets/imges/logo-login.png" />
          </div>
          <div class="login-bg-left-head">Employee Login</div>
          <form [formGroup]="loginForm" (ngSubmit)="login()" class="user">
            <div class="form-group">
              <p [ngClass]="{ 'has-error': isSubmitted && formControls.username.errors }">
                <input type="text" class="form-control form-control-user" id="username" formControlName="username"
                  placeholder="Name">
              </p>

            </div>
            <div>
              <span *ngIf="isSubmitted && formControls.username.errors" class="help-block">
                <span *ngIf="formControls.username.errors.required">Username required</span>
              </span>
            </div>
            <div class="form-group">
              <p [ngClass]="{ 'has-error': isSubmitted && formControls.password.errors }">
                <input type="password" style="width: 100%;" class="form-control form-control-user" id="password"
                  placeholder="Password" formControlName="password">
              </p>

            </div>
            <div>
              <span *ngIf="isSubmitted && formControls.password.errors" class="help-block">
                <span *ngIf="formControls.password.errors.required">Password is required</span>
              </span>
            </div>
            <!-- <div class="form-group">
              <p [ngClass]="{ 'has-error': isSubmitted && formControls.secretekey.errors }">
                <input type="password" class="form-control form-control-user" id="secretekey"
                  formControlName="secretKey" placeholder="Token">
              </p>

            </div>
            <div>
              <span *ngIf="isSubmitted && formControls.secretekey.errors" class="help-block">
                <span *ngIf="formControls.secretekey.errors.required">Token required</span>
              </span>
            </div> -->
            <div>
              <span style="color: rgb(221, 17, 17)">{{this.message}}</span>

            </div>
            <input class="btn btn-primary btn-user btn-block" type="submit" value="Login">

            <div class="login-checkbox-outer">
              <div class="login-checkbox-left">
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
                <label for="vehicle1">Remember Me</label>
              </div>
              <div class="login-checkbox-right ">
                <a href="#">Forgot Password?</a>
              </div>
            </div>
          </form>
        </div>

      </div>

    </div>
    <div class="login-bg-right-inner">
      <div class="login-bg-right-inner-content">
        <p>This login screen is for Apollo employees and if you are not, click here.</p>
        <p>

          Welcome Apollo Manufacturing employees and authorized users. This system is Apollo Manufacturing Inc property.
          Please use the system and information on it responsibly. Unauthorized access or use is strictly prohibited and
          subject to discipline, civil suit or criminal prosecution. By logging on, you agree to theTerms of use for
          this system (click link to review). To prevent unauthorized persons from accessing your account, remember to
          log off of the Apollo Manufacturing Secure Log on system (in addition to the benefits Web sites) and close
          your browser before leaving your computer.
        </p>
        <p>
          Call Apollo Manufacturing Total Access at 212 869 4200. Enter your Apollo Manufacturing ID. When the system
          asks “How can I help you?” say “Password Administration” and follow the instructions. TTY/TDD services are
          available for the hearing impaired at 212 869 4200.</p>
      </div>
    </div>
  </div>
</body>