import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomService } from '@services/manufacture/custom/custom.service';

import { environment } from '../../environments/environment';
import { User } from '@models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    basic = '';

    constructor(private http: HttpClient,
        private customService: CustomService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

    }


    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }


    login(payload: any) {
        let params = new HttpParams();
        params = params.append('grant_type', payload.grantType);
        params = params.append('username', payload.username);
        params = params.append('password', payload.password);

        // params = params.append('code', payload.secretKey);

        this.basic = "Basic " + "Y2xpZW50OnNlY3JldA==";
        let header = new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': this.basic
        });
        let options = { headers: header };
        return this.http.post<any>(environment.api + '/oauth/token', params, options)
            .pipe(map(user => {
                // localStorage.setItem('currentUser', JSON.stringify(user));
                // this.currentUserSubject.next(user);
                return user;
            }));
    }

    loginWithMFA(payload: any) {
        let params = new HttpParams();
        params = params.append('grant_type', payload.grantType);
        params = params.append('mfa_token', payload.mfa_token);
        params = params.append('mfa_add_param', payload.mfa_add_param);
        params = params.append('ismfa_enabled', payload.ismfa_enabled);
        params = params.append('mfa_code', payload.mfa_code);

        this.basic = "Basic " + "Y2xpZW50OnNlY3JldA==";
        let header = new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': this.basic
        });
        let options = { headers: header };
        return this.http.post<any>(environment.api + '/oauth/token', params, options)
            .pipe(map(user => {
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('Logintime', JSON.stringify(new Date()));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('empType');
        localStorage.removeItem('temptoken');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('Logintime');
        this.currentUserSubject.next(null);
        localStorage.removeItem('goldPass');
        this.customService.goldpriceaccessSubject.next(null);
    }

    refreshToken(refreshToken: string) {


        let params = new HttpParams();
        params = params.append('grant_type', "refresh_token");
        params = params.append('refresh_token', refreshToken);

        // params = params.append('code', payload.secretKey);

        this.basic = "Basic " + "Y2xpZW50OnNlY3JldA==";
        let header = new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': this.basic
        });
        let options = { headers: header };
        return this.http.post<any>(environment.api + '/oauth/token', params, options)
            .pipe(map(response => {
                localStorage.setItem('currentUser', JSON.stringify(response));
                this.currentUserSubject.next(response);
                localStorage.removeItem('token');
                localStorage.setItem('token', response.access_token);
                return response;
            }));
    }

}