import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
// import {LoginService} from '@services/login.service';
// import { AuthenticationService } from '@services/authentication.service';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@services/authentication.service';
import { JwtDecodeService } from '@services/core/jwt-decode.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  invalidLogin: boolean = false;
  birthDayList: any;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private jwtDecodeService: JwtDecodeService,
    private titleService: Title, private activatedRoute: ActivatedRoute
  ) {
    this.titleService.setTitle("Login");
  }

  loginForm: FormGroup;
  isSubmitted = false;
  data: any = {};
  loginresult: any = "";
  response: any;

  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  sub: any;
  status: any;
  message: any;

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      //secretKey: ['', Validators.required]

    });
    this.sub = this.activatedRoute
      .queryParams
      .subscribe(params => {
        this.status = params['status'];
        this.message = params['message'];
      });
  }

  get formControls() { return this.loginForm.controls; }
  login() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.data = JSON.stringify(this.loginForm.value);
    const loginPayload = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
      grantType: 'password'
    };
    this.authenticationService.logout();
    this.authenticationService.login(loginPayload)
      .subscribe(
        response => {
          this.response = response;
          localStorage.clear()
          if (this.response && this.response.error == 'mfa_required') {
            localStorage.setItem('birthdays' ,(this.response["mfa_add_param"]));
            
            localStorage.setItem('notification' ,(this.response["mfa_add_param"]));
            response["mfa_add_param"] = this.loginForm.value.password;
            localStorage.setItem('temptoken', JSON.stringify(response));
            this.router.navigate(['/mfalogin']);
          } else {
            this.invalidLogin = true;
          }
        },
        error => {
          this.error = error;
        });
  }
}
